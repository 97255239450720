import { useState, useEffect } from "react";
import axios from "axios";
// import { makeStyles } from '@mui/material/styles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import ModalBook from "../components/BookModal";
import CheckboxImg from "./form/CheckboxImg";



import Typography from "@mui/material/Typography";
import { Package } from "./form/Package";
import Wardrobe from "./form/WardrobeDetails";
import UserInfo from "./form/UserInfo";
import "./stepper.css";
import { Alert } from "flowbite-react";
import ProductCard from "../components/ProductCard";
import estimate from "../img/estimate.png";

import Year from "../img/whychoose/Warranty.png";
import Citie from "../img/whychoose/Cities.png";
import Emi from "../img/whychoose/EasyEMIs.png";
import Checks from "../img/whychoose/Checks.png";
import home from "../img/whychoose/Homes.png";
import Guarantee from "../img/whychoose/Guarantee.png";
import vsOne from "../img/idea-1.png";
import vsTwo from "../img/idea-2.png";
import tenYr from "../img/6yrs.png";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%"
//   },
//   backButton: {
//     marginRight: theme.spacing(1)
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1)
//   }
// }));

const DiscountPath = process.env.REACT_APP_API_DISCOUN;

export const Wizard = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [sqpt, setSqpt] = useState("");
  const [cupboard, setCupboard] = useState("");
  const [frontEnd, setFrontEnd] = useState("");
  const [plyDetails, setPlyDetails] = useState([]);
  const [plyDiscound, setPlyDiscound] = useState('');
  const [TotalAmount, setTotalAmount] = useState([]);
  const [userInfo, setuserInfo] = useState({});
  const [showBookDemo, setShowBookDemo] = useState(false);
  const [userPhone, setuserPhone] = useState({});
  const [allCupboard, setAllCupboard] = useState([])
  const [currentDate, setCurrentDate] = useState("");
  const [discountP, setDiscountP] = useState([])
  const [discountAmount, setDiscountAmount] = useState('')
  const [showToast, setShowToast] = useState(false);
  const [visible, setVisible] = useState(false);





  useEffect(() => {
    setCurrentDate(getCurrentDate());

  }, []);
  useEffect(() => {
    fetchData();

  }, [currentDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000); 
    return () => clearTimeout(timer); // Cleanup the timeout
  }, [visible]);
  const fetchData = async () => {
    try {
      const response = await axios.get(DiscountPath);
      const data = response?.data;


      const filtereds = data.filter((item) => {
        return item.start_date <= currentDate && item.end_date >= currentDate;
      });

      setDiscountP(filtereds);

    } catch (error) {
    } finally {
    }
  };


  function getSteps() {
    return ["Kitchen Cupboard", "Ply Type", "Get Quote"];
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const steps = getSteps();
  function getStepContent(stepIndex) {
    const handleInputChange = (value) => {
      setSqpt(value);
    };
    const handleCupboardChange = (value) => {
      setCupboard(value);
    };
    const handleFrontEndChange = (value) => {
      setFrontEnd(value);
    };
    const handlerAllCupboard = (value) => {
      setAllCupboard(value);
    };

    const handlePassPlyData = (value) => {
      setPlyDetails(value);
    };
    const handlerUserinfo = (formData) => {
      setuserInfo(formData);
    };

    switch (stepIndex) {
      case 0:
        return (
          <Wardrobe
            onInputChange={handleInputChange}
            oncupboardChange={handleCupboardChange}
            onSelectedAllData={handlerAllCupboard}
            onFrontEndChange={handleFrontEndChange}
            InputValue={sqpt}

          />
        );
      case 1:
        return (
          <Package
            onPassPlyData={handlePassPlyData}
            PlyInfo={props.PlyInfo}
            InputValue={setPlyDetails}
            cupboard={cupboard}
            frontEnd={frontEnd}
          />
        );
      case 2:
        return <UserInfo onUserInfo={handlerUserinfo} />;
      default:
        return "Unknown stepIndex";
    }
  }


  const handleNext = () => {
    if (frontEnd === "" || sqpt === "" || cupboard === "") {
      setVisible(true)
    } else {
      
          if (activeStep == "0") {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (activeStep == "1") {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (activeStep == "2") {
            if (Object.keys(userInfo).length === 0) {
            } else {
              const data = {
                userInfo,
                TotalAmount,
                plyDetails,
                sqpt,
                userPhone,
                cupboard,
                frontEnd

              };
              axios.post("/email.php", data).then().catch();
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      setVisible(false)

      }
  };

  useEffect(() => {
    const dis = plyDetails?.rupees;

    // const disamoutPly = plyDetails?.rupees - (plyDetails?.rupees * plyDetails?.discount) / 100
    const disamoutPly = dis

    setPlyDiscound(disamoutPly)
    const frontAndPly = Number(disamoutPly) + Number(frontEnd);
    const totalAmount = Number(sqpt) * Number(frontAndPly);

    const discount = discountP[0]?.discount ?? 0; // Default to 0 if undefined
    const parsedTotalAmount = parseFloat(totalAmount) || 0; // Ensure totalAmount is a number
    const parsedDiscount = parseFloat(discount) || 0; // Ensure discount is a number
    
        const discountAmount = parsedTotalAmount - (parsedTotalAmount * parsedDiscount) / 100;
    setDiscountAmount(discountAmount);
    setTotalAmount(totalAmount);
  }, [plyDetails]);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };

  return (
    <div className="relative">
      {visible && (
        <div className="flex justify-center mb-4  absolute bottom-8 right-[55%] z-[100] ">
          <div
            className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">Incomplete input</span>
          </div>
        </div>
      )}
      <div className="px-4 pt-4 m-auto sm:w-9/12 sm:pt-8">
        <div className="m-auto mt-4 mb-4 sm:w-9/12">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <section className="px-5">
              <div className="w-auto m-auto text-center sm:w-6/12">
                <div className="card-budget">
                  {/* <img className="m-auto w-45 sm:w-80 " src={estimate} alt="" /> */}

                  <div>
                    <h1 className=" text-2xl text-start pb-0 pt-2" >Select imagery world</h1>
                  </div>

                  <CheckboxImg
                    PlyData={allCupboard}
                  // onSelectedPly={selectedPlyHandler}
                  />
                  <h4 className="pb-2 sm:pb-3 sub-head mt-8">
                    Here’s Your Estimate. Kick-start renovations on your dream
                    home today.
                  </h4>

                  <div>
                    <div className="m-auto sm:w-9/12">
                      <div className="pricing">
                        <h4 className="pb-1 mb-2 text-base font-normal border-b text-start ">
                          Here’s a cost estimate for your new home.
                        </h4>

                        {/* <div className="flex justify-between gap-3 mt-2">
                          <small className="text-[14px]">Coating cost </small>
                          <small className="text-[14px]">
                            ₹ {numberWithCommas(Number(frontEnd))} sq.ft
                          </small>
                        </div>
                        <div className="flex justify-between gap-3 mt-2">
                          <small className="text-[14px]">Ply Price </small>
                          <small className="text-[14px]">
                            ₹ {numberWithCommas(plyDiscound)} sq.ft
                          </small>
                        </div> */}
                        <div className="flex justify-between gap-3 mt-2">
                          <small className="text-[14px] text-left	">Base product & finish coating cost </small>
                          <small className="text-[14px] text-right	">
                            ₹ {numberWithCommas(Number(frontEnd)+Number(plyDiscound))} sq.ft
                          </small>
                        </div>
                        <div className="flex justify-between gap-3 mt-2">
                          <small className="text-[14px] text-left	">Total square feet </small>
                          <small className="text-[14px] text-right">
                            {numberWithCommas(sqpt)}
                          </small>
                        </div>
                        {/* <div className="flex justify-between">
                          <span>Discount </span>{" "}
                          <span>{plyDetails.discount} %</span>
                        </div> */}
                        <div className="flex justify-between mt-2 border-t pt-2 ">
                          <span className="text-[14px] font-medium	">Total amount </span>
                          <div className="flex flex-col justify-self-end	items-end	 ">
                            <del> <span className="text-[14px] font-medium	text-right ">₹ {numberWithCommas(TotalAmount)}</span></del>
                            <small className="text-[12px] text-[green] text-right">{discountP[0]?.discount}% Discount</small>
                          </div>

                        </div>
                        <div className="flex justify-between mt-1  final-amout">
                          <span className="text-left">Final amount </span>
                          <span className="text-right">₹ {numberWithCommas(discountAmount)}</span>
                        </div>
                      </div>
                      <small>*accessories extra</small>
                      <small className="mt-5">
                        This is only an approximate price calculation based on
                        how much the client is spending. The final cost can be
                        higher or lower depending on the overall product, number
                        of furniture, design elements, and wood type. Don’t
                        worry our designers will do the best for you.
                      </small>
                      <div className="mt-5 sm:mt-10">
                        {/* <p>Have suggestions? Get in touch with us.</p> */}
                        <div className="mt-2 text-center">
                          {/* <button
                            className="btn btn-primary"
                            onClick={BookDemoHandler}
                          >
                            Get Free Quote
                          </button> */}
                        </div>
                      </div>
                      {/* <Button onClick={handleReset}>Reset</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <div>All steps completed</div> */}
            <section className="section">
              <div className="title-section">
                <h5 className="sm-head">Why Choose Us</h5>
                <h4 className="sub-head">Boasts stunning interior design.</h4>
                <p>
                  We specialize in crafting home interiors customized to suit
                  your unique vision and needs. With a passion for aesthetics
                  and a commitment to excellence, our team of highly talented
                  designers and architects strive to make the dream homes of our
                  clients a reality. Be it a residential or commercial makeover
                  or a hospitality revamp. We strive to deliver renovative
                  solutions that exceed expectations.
                </p>

                <div className="grid grid-cols-2 gap-3 mt-6 md:gap-6 lg:grid-cols-3">
                  <div className="card-icon">
                    <div className="icon">
                      <img src={Year} alt="" />
                    </div>

                    <h6>6 Year Warranty</h6>
                  </div>
                  <div className="card-icon">
                    <div className="icon">
                      <img src={Emi} alt="" />
                    </div>

                    <h6>Easy EMIs</h6>
                  </div>
                  <div className="card-icon">
                    <div className="icon">
                      <img src={Guarantee} alt="" />
                    </div>

                    <h6>45 Days Guarantee</h6>
                  </div>
                  <div className="card-icon">
                    <div className="icon">
                      <img src={Checks} alt="" />
                    </div>

                    <h6>146 Quality Checks</h6>
                  </div>
                  <div className="card-icon">
                    <div className="icon">
                      <img src={home} alt="" />
                    </div>

                    <h6>More Happy Homes</h6>
                  </div>
                  <div className="card-icon">
                    <div className="icon">
                      <img src={Citie} alt="" />
                    </div>

                    <h6>50+ Cities</h6>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="title-section">
                <h5 className="sm-head">our products</h5>
                <h4 className="sub-head">
                  A comprehensive destination for all your interior needs.
                </h4>
                <p>
                  Whether you seek complete interior solutions, renovations, or
                  modular options, we offer a comprehensive range for both home
                  and office needs. From furniture to decor, we provide support
                  throughout your project journey.
                </p>
              </div>
              <div className="mb-10">
                <ProductCard />
              </div>
            </section>
          </div>
        ) : (
          <div className="py-4 pb-6 m-auto sm:w-9/12">
            <div className="m-auto mb-4 cards-stepper sm:w-9/12">
              <div>{getStepContent(activeStep)}</div>

              <div className="stepper-footer">
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>

                <button
                  variant="contained"
                  className="btn btn-primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length ? "Get my estimate" : "Next"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {showToast && (
        <div
          id="toast-default"
          className="fixed top-[30%] right-5 flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow"
          role="alert"
        >
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            {/* Icon */}
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
            </svg>
            <span className="sr-only">Info icon</span>
          </div>
          <div className="ml-3 text-sm font-normal">Enter input field.</div>
          <button
            type="button"
            onClick={() => setShowToast(false)}
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:text-gray-900 hover:bg-gray-100 inline-flex h-8 w-8 "
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      )}

      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />
    </div>
  );
};
